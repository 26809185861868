const types = {
  AGENT: 0,
  SUB_AGENT: 3,
  AGGREGATOR_SUBAGENT: 2,
  AGGREGATOR: 1,
  FIELD_STAFF: 4,
  APIAgent: 5,
  APISubAgent: 6,
}

export const Agent = {
  checkType(agent, type) {
    return [type].includes(agent.agent_type)
  },
  isSubAgent(agent) {
    return Agent.checkType(agent, types.SUB_AGENT)
  },
  isAgent(agent) {
    return Agent.checkType(agent, types.AGENT)
  },
  isAggregatorSubAgent(agent) {
    return Agent.checkType(agent, types.AGGREGATOR_SUBAGENT)
  },
  isAggregator(agent) {
    return Agent.checkType(agent, types.AGGREGATOR)
  },
  isFieldStaff(agent) {
    return Agent.checkType(agent, types.FIELD_STAFF)
  },
  isAPIAgent(agent) {
    return Agent.checkType(agent, types.APIAgent)
  },
  isAPISubAgent(agent) {
    return Agent.checkType(agent, types.APISubAgent)
  },
}

export const roles = {
  SUPPORT: 'Support',
  SUPER_ADMIN: 'SuperAdmin',
  OPPERATION_HEAD: 'OperationHead',
  ADMIN: 'Admin',
  OPERATIONS_MANAGER: 'Admin',
  OPERATIONS_ASSISTANT: 'OperationsAssistant',
  FIELD_SUPERVISOR: 'FieldSupervisor',
  AGENT: 'agent',
  SERVICE_OWNER: 'Support',
  ACCOUNTS_TEAM: 'AccountTeam',
  POS_TEAM: 'PosTeam',
  COMMS: 'Comms',
  GROWTH_MANAGER: 'GrowthManager',
  REGIONAL_RETENTION_MANAGER: 'RegionalRetentionManager',
  AGGREGATOR_MANAGER: 'AggregatorManager',
  KYC_TEAM: 'kycteam',
}

const STAFF_ROLES = Object.values(roles).filter((r) => r.toLowerCase() !== 'agent')

export const User = {
  checkRole(user, role) {
    return [role].includes(user.user_role)
  },
  isOperationHead(user) {
    return User.checkRole(user, roles.OPPERATION_HEAD)
  },
  isSupport(user) {
    return User.checkRole(user, roles.SUPPORT)
  },
  isFieldSupervisor(user) {
    return User.checkRole(user, roles.FIELD_SUPERVISOR)
  },
  isAdmin(user) {
    return User.checkRole(user, roles.ADMIN)
  },
  isSuperAdmin(user) {
    return User.checkRole(user, roles.SUPER_ADMIN)
  },
  isAgent(user) {
    return User.checkRole(user, roles.AGENT)
  },
  isServiceOwner(user) {
    return User.checkRole(user, roles.SERVICE_OWNER)
  },
  isAccountTeam(user) {
    return User.checkRole(user, roles.ACCOUNTS_TEAM)
  },
  isPosTeam(user) {
    return User.checkRole(user, roles.POS_TEAM)
  },
  isComms(user) {
    return User.checkRole(user, roles.COMMS)
  },
  isAStaff(user) {
    return User.isOneOf(user, STAFF_ROLES)
  },
  isGrowthmanager(user) {
    return User.isOneOf(user, roles.GROWTH_MANAGER)
  },
  isRegionalRetentionManager(user) {
    return User.isOneOf(user, roles.REGIONAL_RETENTION_MANAGER)
  },
  isAggregatorManager(user) {
    return User.isOneOf(user, roles.AGGREGATOR_MANAGER)
  },
  isKycTeam(user) {
    return User.checkRole(user, roles.KYC_TEAM)
  },

  isNot(_role, user) {
    return !User.checkRole(user, _role)
  },

  isOneOf(user, roles) {
    return roles.some((role) => User.checkRole(user, role))
  },
}
export const AllRoles = Object.values(roles)
export const except = (_roles) => Object.values(roles).filter((r) => !_roles.includes(r))

export const HIGH_ROLES = [
  roles.SUPER_ADMIN,
  roles.ADMIN,
  roles.OPERATIONS_ASSISTANT,
  roles.OPERATIONS_MANAGER,
  roles.OPPERATION_HEAD,
]

export const LIEN_ROLES = ['pfkayode', 'pfmartha', 'fmalex', 'fmoluseguna', 'crfagwu']

export const NOTIFICATION_VIEW = ['pfshadrach', 'fmotavio']
export const AGENTTYPE_VIEW = ['fmjaphet']
export const COMPLIANCE_BVN_VIEW = ['fmpeniel', 'fmblessingakin', 'pfjames', 'fmokhian', 'fmmercy']
