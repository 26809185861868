const theme = {
  breakpoints: ['40em', '52em', '64em'],
  reactDatepicker: {
    daySize: [36, 40],
    fontFamily: 'inherit',
    colors: {
      accessibility: '#e3ecf4',
      selectedDay: '#7e99b1',
      selectedDayHover: '#325c81',
      primaryColor: '#1c3244',
    },
  },
  colors: {
    text: '#8b9ba8',
    background: '#f5f8fa',
    highlight: '#1c3244',
    grey: '#dbe0e5',
    lightgrey: '#e3ecf4',
    white: '#fff',
    dark: '#0d2d4a',
    green: '#4db142',
    red: '#d95858',
    brown: '#ed9c6a',
    yellow: '#f1de29',
    blue: ['#3ca1ca', '#40cdff', '#0f375a', '#bfe1ff', '#d9f5ff', '#7e99b1', '#325c81', '#283655', '#061624'],
    active: '#12B76A',
    inactive: '#F3A300',
    failed: '#1c3244',
    orange: '#F6851F',
  },
  fonts: ['Avenir'],
  fontSizes: [9, 12, 14, 16, 18, 24, 30, 32, 48, 64, 72],
  sizes: [0, 4, 8, 16, 32, 64, 128, 256, 512],
  space: [0, 2, 4, 8, 14, 16, 20, 32, 48, 64, 72, 96],
}

theme.fontSizes.xxs = theme.fontSizes[0] //9
theme.fontSizes.xs = theme.fontSizes[1] //12
theme.fontSizes.sm = theme.fontSizes[2] //14
theme.fontSizes.md = theme.fontSizes[4] //18
theme.fontSizes.lg = theme.fontSizes[5] //24
theme.fontSizes.xl = theme.fontSizes[6] //32
theme.fontSizes.xxl = theme.fontSizes[7] //48
theme.fontSizes.xxxl = theme.fontSizes[8] //64
theme.fontSizes.xxxxl = theme.fontSizes[8] //72

theme.space.tiny = theme.space[2]
theme.space.xs = theme.space[3] // 14
theme.space.sm = theme.space[4] // 16
theme.space.md = theme.space[5] // 20
theme.space.lg = theme.space[6] // 32
theme.space.xl = theme.space[7] // 48
theme.breakpoints.sm = theme.breakpoints[0]
theme.breakpoints.md = theme.breakpoints[1]
theme.breakpoints.lg = theme.breakpoints[2]
export const buttonVariants = {
  dark: {
    backgroundColor: '#1c3244',
    color: 'white',
    borderColor: '#1c3244',
  },
  info: {
    backgroundColor: '#d9f5ff',
    color: '#2ca1ca',
    borderColor: '#2ca1ca',
  },
  debit: {
    backgroundColor: '#8DB5EF',
    color: '#fff',
    borderColor: '#8DB5EF',
  },
  refund: {
    backgroundColor: '#68C9A4',
    color: '#fff',
    borderColor: '#68C9A4',
  },
  blue: {
    backgroundColor: '#2ca1ca',
    color: 'white',
    borderColor: '#2ca1ca',
  },
  success: {
    backgroundColor: '#68c9a4',
    color: 'white',
    borderColor: 'transparent',
  },
  error: {
    backgroundColor: '#d95858',
    borderColor: '#d95858',
    color: 'white',
  },
  default: {
    borderColor: '#e3ecf4',
    backgroundColor: '#f5f8fa',
    color: '#8b9ba8',
  },
  text: {
    borderColor: 'transparent',
    backgroundColor: '#f5f8fa',
    color: '#8b9ba8',
  },
  warning: {
    backgroundColor: '#ffc107',
    borderColor: '#eeb236',
    // color: '#212529',
    color: '#876326',
  },
  fairmoney: {
    backgroundColor: '#37A477',
    borderColor: '#37A477',
    color: 'white',
  },
  pending: {
    backgroundColor: '#DEDEDE',
    borderColor: '#DEDEDE',
    color: 'white',
  },
  restore: {
    backgroundColor: '#C8CBCE',
    borderColor: '#1c3244',
    color: '#1c3244',
  },
}
export default theme
