import React, { useState } from 'react'
import Spacer from 'design/elements/Spacer'
import Box from 'design/elements/Box'
import Text from 'design/elements/Text'
import Divider from 'design/elements/Divider'
import TextField from 'design/elements/TextField'
import { useForm } from 'react-hook-form'
import { Agent, roles } from 'lib/models'
import FieldText from 'design/elements/FieldText'
import useUpdateAgent from 'agents/hooks/useUpdateAgent'
import { paschalKeys } from 'lib/formatters'
import Panel from 'design/elements/Panel'
import { HideTree } from 'lib/gate/GateComponents'
import { InputLoader } from 'design/loaders'
import { useToast } from 'toast/ToastContext'

/**
 * Forms
 */
import AgentInfoDetails from 'agents/forms/AgentInfoDetails'
import BasicDetailsForm from 'agents/forms/BasicDetailsForm'
import BankDetailsForm from 'agents/forms/BankDetailsForm'
import BusinessInfoDetails from 'agents/forms/BusinessInfoDetails'
import LienForm from 'agents/forms/LienForm'
import NextOfKinForm from 'agents/forms/NextOfKinForm'
import RecruiterForm from 'agents/forms/RecruiterForm'
import TransactionLimitForm from 'agents/forms/TransactionLimitForm'
import SetTransactionLimitForm from './SetTransferLimitForm'
import AgentKycDocuments from 'agents/panels/AgentKycDocuments'
import KycDataVerification from './KycDataVerification'
import AdditionalContactDetailsModal from 'agents/modals/AdditionalContactDetailsModal'
import AdditionalContactDetailsForm from './AdditionalContactDetailsForm'
import AdditionalContactDetails from 'agents/panels/AdditionalContactDetails'

function removeStringValues(keys, object) {
  let result = {}
  for (let [key, value] of Object.entries(object)) {
    if (!keys.includes(key)) {
      result[key] = value
      continue
    }
    if (typeof value !== 'string') {
      result[key] = value
    }
  }
  return result
}

export default function AgentForm({ agent, notify, alert, clearAlert, isDataFromServer }) {
  const form = useForm({
    mode: 'onBlur',
    defaultValues: Object.assign(
      {},
      { lien_amount: null, lien_reason: '' },
      agent,
      { change_agent_type: false },
      { change_account_type: false }
    ),
  })
  let registered = React.useRef(false)
  const [{ status }, updateAgent] = useUpdateAgent(agent, {
    notify,
    alert,
    clearAlert,
  })

  const [is_kyc_verified, changeIsKycVerified] = useState(agent?.is_kyc_verified)

  const [selectedIDType, setSelectedIDType] = useState('')
  const [currentId, setCurrentId] = useState('IdentityType')

  const updateDetails = React.useCallback(
    (values) => {
      const t =
        currentId === 'IdentityType' ? { IdentityType: selectedIDType } : { PrimaryIdentityType: selectedIDType }

      const _body = Object.assign({}, agent, values, {
        lien_amount: Number.isFinite(+values.lien_amount) ? values.lien_amount : null,
        is_kyc_verified,
        first_name: null,
        last_name: null,
        // ...t,
      })
      const body = removeStringValues(
        [
          'identity',
          'passport',
          'evidence_of_address',
          'c_a_c_document',
          'c_a_c_status_report',
          'allotment_of_shares',
          'particulars_of_director',
          'board_resolution_consenting_to_with_fair_money',
          'particulars_of_proprietor',
          'cac_certified_application_form',
          'evidence_of_p_o_s_active_rate',
          'm_e_m_a_r_t',
          'incorporated_trustees_application_form',
          'constitution',
        ],
        _body
      )
      updateAgent(paschalKeys(body))
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      // updateAgent,
      agent,
      is_kyc_verified,
      selectedIDType,
    ]
  )
  const individualAccount = agent.business_account_type === 10
  const corporateAccount = agent.business_account_type === 20
  return (
    <>
      <Panel py="lg" px="lg">
        <Text textTransform="uppercase" fontWeight="600" fontSize="16px" color="#8095A7">
          {individualAccount
            ? 'Individual / Freelance Business account'
            : corporateAccount
            ? 'Corporate Business account'
            : 'Individual/Corporate Business account'}
        </Text>
      </Panel>
      <Spacer mb="sm"></Spacer>
      <Box
        // as="form"
        // onSubmit={form.handleSubmit(updateDetails)}
        fontSize="sm"
        display="grid"
        gridTemplateColumns="repeat(3, 1fr)"
        gridGap="3em"
        mb="7em"
      >
        <HideTree forRoles={[roles.FIELD_SUPERVISOR]}>
          <ColumnOne
            form={form}
            agent={agent}
            setSelectedIDType={setSelectedIDType}
            selectedIDType={selectedIDType}
            setCurrentId={setCurrentId}
            currentId={currentId}
            loading={!isDataFromServer}
          />
          <ColumnTwo is_kyc_verified={is_kyc_verified} onChangeKyc={changeIsKycVerified} form={form} agent={agent} />
        </HideTree>
        <Box>
          <ColumnThree form={form} agent={agent} />
        </Box>
      </Box>
    </>
  )
}

function ColumnOne({ agent, form, loading, selectedIDType, setSelectedIDType, currentId, setCurrentId }) {
  const { notify, clearAlert, alert: ToastAlert } = useToast()
  const url = window.location.href
  const agentId = url.split('/')[4]

  if (loading) {
    return <InputLoader />
  }

  return (
    <Box>
      <Panel py="lg" px="lg">
        <BasicDetailsForm agent={agent} roles={roles} notify={notify} clearAlert={clearAlert} alert={ToastAlert} />
        <Spacer mt="xl" />
        <AdditionalContactDetailsForm agent={agent} notify={notify} clearAlert={clearAlert} alert={ToastAlert} />
        <AdditionalContactDetails AgentId={agentId} />
        <Spacer mt="xl" />
        <BusinessInfoDetails
          agent={agent}
          agentId={agentId}
          notify={notify}
          clearAlert={clearAlert}
          alert={ToastAlert}
        />

        <Spacer mt="xl" />
        <NextOfKinForm agent={agent} notify={notify} clearAlert={clearAlert} alert={ToastAlert} />
      </Panel>
    </Box>
  )
}

function ColumnTwo({ agent, form, is_kyc_verified, onChangeKyc }) {
  const { notify, alert: ToastAlert, alert, clearAlert } = useToast()
  const url = window.location.href
  const agentId = url.split('/')[4]

  return (
    <Panel py="lg" px="lg">
      <TransactionLimitForm agent={agent} alert={alert} clearAlert={clearAlert} notify={notify} roles={roles} />
      <Spacer mt="xl" />
      <SetTransactionLimitForm agent={agent} alert={alert} clearAlert={clearAlert} notify={notify} roles={roles} />
      <Spacer mt="xl" />
      <LienForm agent={agent} alert={alert} clearAlert={clearAlert} notify={notify} />
      <Spacer mt="xl" />
      <KycDataVerification agent={agent} />
      <Spacer mt="xl" />
      <RecruiterForm agent={agent} alert={alert} clearAlert={clearAlert} notify={notify} />
      <Spacer mt="xl" />
      <AgentInfoDetails agent={agent} agentId={agentId} notify={notify} clearAlert={clearAlert} alert={ToastAlert} />
    </Panel>
  )
}

function ColumnThree({ agent, form }) {
  const { notify, alert, clearAlert } = useToast()

  return (
    <Panel py="lg" px="lg">
      <AgentKycDocuments agent={agent} notify={notify} />
      <Spacer mt="xl" />
      <Text textTransform="uppercase" fontWeight="bold">
        Sponsor information
      </Text>
      <Divider />
      <FieldText isLabel color="highlight" mb="xs">
        Sponsor Full Name
      </FieldText>
      <FieldText color="highlight" border="1px solid #CADCE0" padding="10px 16px">
        {agent?.sponsor_name}
      </FieldText>
      <Spacer mt="xl" />

      <BankDetailsForm agent={agent} alert={alert} clearAlert={clearAlert} notify={notify} />
      {Boolean(agent) && Agent.isAggregator(agent) && (
        <>
          <Spacer mt="lg" />
          <TextField
            label="Aggregator Code"
            name="aggregator_code"
            error={form.errors?.aggregator_code}
            helperText={form.errors?.aggregator_code?.message && 'Please enter a  valid aggregator code'}
            ref={form.register({ required: false, minLength: 3 })}
            defaultValue={agent?.aggregator_code}
          />
        </>
      )}
    </Panel>
  )
}
